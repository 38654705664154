/* src/pages/NotFound.css */
.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  text-align: center;
}

.not-found-title {
  font-size: 6rem;
  font-weight: 700;
  color: #343a40;
  margin: 0;
}

.not-found-message {
  font-size: 1.5rem;
  color: #6c757d;
  margin: 1rem 0;
}

.not-found-link {
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
  border: 1px solid #007bff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.not-found-link:hover {
  background-color: #007bff;
  color: #fff;
}
